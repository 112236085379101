$("#contact_form").submit(function (event) {

    $form = $(this);
    event.preventDefault();

    $('.js-errors').html('').hide();
    $('.js-success').html('').hide();

    var formData = $form.serialize();

    $.post( routes['contact_form_submit'], formData, function( response ) {
        if(response.success==0){
            displayAjaxErrors({'error':response.message}, $('.js-errors'));
            var target = $('.js-errors');
            $(target).html('')
            $(target).fadeIn()
            $.each(errors, function(key, value) {
                $(target).append('<div>'+value+'</div>')
            })
            setTimeout(function() {
                $(target).fadeOut()
            }, 4000)
        }else{
            $('#contact_form')[0].reset();
            console.log(response.message);
            var target = $('.js-success');
            $(target).html('')
            $(target).append('<div>'+response.message+'</div>')
            $(target).fadeIn()
        }
    }).fail(function(error) {
        var target = $('.js-errors');
        $(target).html('')
        $(target).fadeIn()
        $(target).append('<div>'+texts['contact']['feedback_ko']+'</div>')
        setTimeout(function() {
            $(target).fadeOut()
        }, 4000)
    });
});
